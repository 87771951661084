<template>
  <div class="page-error">
    <img src="@/assets/img/icons/404.svg" alt="404" class="img-responsive">
    <h1>Ошибка: 404 страница не найдена</h1>
    <p>К сожалению, страница, которую вы ищете, недоступна.</p>
    <el-button
        type="primary"
        @click="$router.push({path: '/'})"
    >
      Вернуться на главную
    </el-button>
  </div>
</template>

<script>


export default {
  name: 'NotFound',
  components: {},

  data() {
    return {

    }
  },
  computed: {},

  mounted() {},
  methods: {}
}

</script>

<style lang="scss">
@import "./src/assets/css/project-variables";

.page-error {
  height: calc(100vh - 190px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    margin-bottom: 8px;
  }

  p {
    color: $--color-text-regular-6;
    margin-bottom: 48px;
  }

  @media only screen and (max-width: 1480px) {
    height: calc(100vh - 140px);

    img {
      width: 25%;
    }

    p {
      margin-bottom: 24px;
    }
  }
}

</style>
