export const paginator = {
    data() {
        return {
            paginator: {
                param: 'page',
                paramPerPage: 'per-page',
                page: 1,
                currentPage: null,
                pageCount: null,
                perPage: 25,
                totalCount: null,
                perPageSizes: [25, 50, 75, 100],
            },
        }
    },
    methods: {
        paginatorFill(paginator){
            this.paginator.currentPage = paginator.currentPage;
            this.paginator.pageCount = paginator.pageCount;
            this.paginator.perPage = paginator.perPage;
            this.paginator.totalCount = paginator.totalCount;
        },

    }
}